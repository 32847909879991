import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../../hooks/useMediaQuery"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const Values = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      700: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  return (
    <div className="qvalues">
      {isDesktop ? (
        <div className="row aiwrapper">
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }} className="text-center pt-3">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid "
                  src="https://cdn.sanity.io/images/55lcecww/production/2cc4e0ecdf3f9ab27011e7e3f1f41b6082c66b35-60x60.png"
                  alt="Expertise"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold text-center">
                Business and
                <br /> Customer Value
              </p>
              <p>
                Your AI-enabled transformation starts here. This dimension
                centers on defining the business strategy, experience, products,
                and services that will be improved with AI to innovate and
                create value for your customers.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }} className="text-center pt-3">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid "
                  src="https://cdn.sanity.io/images/55lcecww/production/943341fcb5bb7f3b7d6a432c2a4596b14737a276-60x60.png"
                  alt="Perspective"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold text-center">
                Strategy <br />
                Alignment
              </p>
              <p className="text-black">
                Your strategy defines the planning and tactical framework for
                integrating AI into your business and service operations. This
                dimension includes setting AI-driven objectives, aligning
                leadership to your desired outcomes, and managing resources for
                optimal returns through integrated delivery.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="rounded">
              <div style={{ height: "3.5rem" }} className="text-center pt-3">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid "
                  src="https://cdn.sanity.io/images/55lcecww/production/ce7394784e944fbfb484dd2b403348ee1307ddc5-60x60.png"
                  alt="Adoption"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <p className="h5 text-black pb-2 font-weight-bold header-services text-center">
                Technology
                <br />
                and Data
              </p>
              <p>
                For AI to truly impact your organization, your data and IT
                infrastructure must be ready for the growing demands of AI
                operations. Your IT infrastructure, data management, MLOps, and
                technology adoption are all foundational to support your future
                AI capabilities.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-swiper pb-5 aiwrapper">
          <LazyLoadComponent>
            <Swiper {...params}>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div
                      style={{ height: "3.5rem" }}
                      className="img-bg text-center pt-3"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/2cc4e0ecdf3f9ab27011e7e3f1f41b6082c66b35-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                      {/* <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/2cc4e0ecdf3f9ab27011e7e3f1f41b6082c66b35-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold text-center">
                      Business and
                      <br /> Customer Value
                    </p>
                    <p>
                      Your AI-enabled transformation starts here. This dimension
                      centers on defining the business strategy, experience,
                      products, and services that will be improved with AI to
                      innovate and create value for your customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div
                      style={{ height: "3.5rem" }}
                      className="img-bg text-center pt-3"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/943341fcb5bb7f3b7d6a432c2a4596b14737a276-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                      {/* <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/943341fcb5bb7f3b7d6a432c2a4596b14737a276-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold text-center">
                      Strategy <br />
                      Alignment
                    </p>
                    <p className="text-black">
                      Your strategy defines the planning and tactical framework
                      for integrating AI into your business and service
                      operations. This dimension includes setting AI-driven
                      objectives, aligning leadership to your desired outcomes,
                      and managing resources for optimal returns through
                      integrated delivery.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div
                      style={{ height: "3.5rem" }}
                      className="img-bg text-center pt-3"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/ce7394784e944fbfb484dd2b403348ee1307ddc5-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                      {/* <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/ce7394784e944fbfb484dd2b403348ee1307ddc5-60x60.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services text-center">
                      Technology
                      <br />
                      and Data
                    </p>
                    <p>
                      For AI to truly impact your organization, your data and IT
                      infrastructure must be ready for the growing demands of AI
                      operations. Your IT infrastructure, data management,
                      MLOps, and technology adoption are all foundational to
                      support your future AI capabilities.
                    </p>
                  </div>
                </div>
              </div>
            </Swiper>
          </LazyLoadComponent>
        </div>
      )}
    </div>
  )
}
export default Values
